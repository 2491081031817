import React, { Component } from "react";
import {
  FiCast,
  FiLayers,
  FiUsers,
  FiMonitor,
  FiFilm,
  FiFacebook,
  FiInstagram,
} from "react-icons/fi";

const ServiceList = [
  {
    icon: <FiInstagram />,
    title: "Social Media Advertisement",
    description:
      "The Facebook/Instagram Advertising platform is able to track how many times an ad appears on a page, how many users clicks ads, and which ad clicks led to conversions. We track this daily and optimize your ad campaigns based off the data we gather.",
  },
  {
    icon: <FiFilm />,
    title: "Content Creation",
    description:
      "Our team will create content for your social media that will ultimately create leads for your business. We will strategize a plan for you that will allow you to consistently keep your social media pages up to date with new and unique content every week.",
  },
  //   {
  //     icon: <FiMonitor />,
  //     title: "Website Development",
  //     description:
  //       "I throw myself down among the tall grass by the stream as I lie close to the earth.",
  //   },

  //   {
  //     icon: <FiMonitor />,
  //     title: "Mobile App Development",
  //     description:
  //       "I throw myself down among the tall grass by the stream as I lie close to the earth.",
  //   },
  //   {
  //     icon: <FiUsers />,
  //     title: "Marketing & Reporting",
  //     description:
  //       "I throw myself down among the tall grass by the stream as I lie close to the earth.",
  //   },
  //   {
  //     icon: <FiMonitor />,
  //     title: "Mobile App Development",
  //     description:
  //       "I throw myself down among the tall grass by the stream as I lie close to the earth.",
  //   },
];

class ServiceThree extends Component {
  render() {
    const { column } = this.props;
    const ServiceContent = ServiceList.slice(0, this.props.item);

    return (
      <React.Fragment>
        <div className="row">
          {ServiceContent.map((val, i) => (
            <div className={`${column}`} key={i}>
              {/* <a href="/service-details"> */}
              <div className="service service__style--2">
                <div className="icon">{val.icon}</div>
                <div className="content">
                  <h3 className="title">{val.title}</h3>
                  <p>{val.description}</p>
                </div>
              </div>
              {/* </a> */}
            </div>
          ))}
        </div>
      </React.Fragment>
    );
  }
}
export default ServiceThree;
