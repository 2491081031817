import React, { Component } from "react";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { FiCheck } from "react-icons/fi";

class TabsOne extends Component{
    render(){
        let 
        tab1 = "Our history", 
        tab2 = "Our mission",
        tab3 = "Friendly Support";
        const { tabStyle } = this.props;
        var namesItemOne = [
            'Efficient onboarding process',
            'Consistent communication',
            'Creativity and acceptance to new ideas',
            'Adaptation to new market conditions',
        ];
       
        return(
            <div>
                {/* Start Tabs Area */}
                <div className="tabs-area">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <Tabs>
                                    <TabList  className={`${tabStyle}`}>
                                        <Tab>{tab1}</Tab>
                                        <Tab>{tab2}</Tab>
                                        {/* <Tab>{tab3}</Tab> */}
                                    </TabList>

                                    <TabPanel>
                                       <div className="single-tab-content">
                                           <p>Expoz Media was founded in Los Angeles in 2019. Before we put this team together, we were all on our own freelancing and taking on any social media related jobs. Now we have this small but talented team that has put all it's skills together to serve online based businesses.  </p>

                                           <div className="mt--30">
                                               <h4>Expectations From You</h4>
                                               <ul className="list-style--1">
                                                   {namesItemOne.map((name, index) => {
                                                       return <li key={ index }><FiCheck /> {name}</li>;
                                                   })}
                                               </ul>
                                           </div>
                                       </div>
                                    </TabPanel>

                                    <TabPanel>
                                       <div className="single-tab-content">
                                           <p>We want to work with partners that are willing to build a relationship with our team. Creating a real business relationship is our goal because we know that those are the partnerships that help a business thrive. The power of social media is limitless and we are confident in our teams ability to strategize ad campaigns to exponentionally grow your business. With more than 5 years of experience, you can trust us to be the brains behind your marketing strategies. </p>
                                        
                                       </div>
                                    </TabPanel>

                                    <TabPanel>
                                       <div className="single-tab-content">
                                           <p>Popy ipsum dolor sit amet consectetur adipisicing elit. Neque ab aliquid, atque enim, vero nobis quam beatae nesciunt aliquam molestias, optio hic laborum esse. Deserunt architecto officiis laudantium corporis voluptatem.</p>
                                           <p>Popy ipsum dolor sit amet consectetur adipisicing elit. Neque ab aliquid, atque enim, vero nobis quam beatae nesciunt aliquam molestias, optio hic laborum esse. Deserunt architecto officiis laudantium corporis voluptatem.</p>
                                           <p>Popy ipsum dolor sit amet consectetur adipisicing elit. Neque ab aliquid, atque enim, vero nobis quam beatae nesciunt aliquam molestias, optio hic laborum esse. Deserunt architecto officiis laudantium corporis voluptatem.</p>
                                       </div>
                                    </TabPanel>
                                    
                                </Tabs>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Tabs Area */}
            </div>
        )
    }
}



export default TabsOne;